<template>
  <div>
    <v-sheet :class="g_darkmode ? '' : 'white'" class="pa-4">
      <v-row no-gutters align="center">
        <span
          class="d-flex"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'DuesMaster'
            })
          "
        >
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          <span class="d-flex font-weight-bold">
            <span class="mr-1">{{ $t("dues.dues") }}</span>
            - {{ duesDetail.name }}
          </span>
        </span>
        <v-spacer />
        <v-btn
          class="gradient-primary mt-2"
          dark
          depressed
          @click="setAction('master')"
        >
          <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
          {{ $t("dues.master_dues.dialog.add_dues") }}
        </v-btn>
      </v-row>
      <v-divider class="mt-4 mb-2" />
      <v-row no-gutters class="mb-4" align="center">
        <v-select
          v-model="queryData.category"
          :items="categoryList"
          item-text="label"
          item-value="key"
          label="Level"
          outlined
          hide-details
          dense
          class="mr-2 select-150"
          clearable
          @change="getDuesPayerHandler"
        />
        <v-autocomplete
          v-model="queryData.payer"
          :label="$t('dues.level_name')"
          :items="payerList"
          :loading="loadingPayer"
          item-text="label"
          item-value="key"
          outlined
          hide-details
          dense
          class="mr-2 select-200"
          clearable
          @change="getDuesMasterHandler"
        />
        <span class="font-weight-bold">{{ total }} Iuran</span>

        <v-spacer />

        <v-select
          v-model="queryData.status"
          :label="$t('dues.table.status')"
          :items="statusList"
          item-text="label"
          item-value="key"
          outlined
          hide-details
          dense
          class="mr-2 select-200"
          @change="getDuesMasterHandler"
        />
      </v-row>

      <v-sheet>
        <v-data-table
          :headers="tableHeaders"
          :items="dataTable"
          :page.sync="queryData.page"
          :items-per-page="queryData.limit"
          :loading="loading"
          hide-default-footer
          disable-pagination
          class="elevation-0"
        >
          <template v-slot:[`item.category`]="{ item }">
            {{ categoryList.find(c => c.key === item.category).label }}
          </template>
          <template v-slot:[`item.nominal`]="{ item }">
            Rp. {{ item.nominal | priceFormat }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{
              statusList.length > 0
                ? statusList.find(s => s.key === item.status).label
                : item.status
            }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  icon
                  small
                  class="mr-2 primary"
                  @click="setEdit(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("dues.table.edit") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  icon
                  small
                  class="primary"
                  @click="setDeleteDues(item)"
                >
                  <v-icon small>mdi-minus-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("dues.table.non_active") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />

        <DuesListForm
          :isOpen="dialogMasterForm"
          :data="selectedDuesMaster"
          :categoryList="categoryList"
          :schoolYearId="g_user.active_schoolyear.school_year_master"
          :close="
            param => {
              dialogMasterForm = false;
              selectedDuesMaster = null;
              if (param) this.getDuesMasterHandler();
            }
          "
        />

        <ModalConfirm
          :title="$t('dues.dialog.confirm_status')"
          :close="() => (this.confirmDelete = false)"
          :loading="loadingDelete"
          :isOpen="confirmDelete"
          :save="deleteMasterDues"
        >
          <span>{{ $t("dues.dialog.confirm_delete") }}</span>
        </ModalConfirm>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import DuesListForm from "./DuesListForm.vue";
import ModalConfirm from "@/components/ModalConfirm";
import {
  getDuesList,
  getDuesStatus,
  getDuesCategory,
  detailDuesMaster,
  getDuesPayer,
  setStatusDues
} from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

let typingTimer;
const doneTypingInterval = 750;

export default {
  filters: {
    priceFormat: value => {
      if (!value) return "-";
      return priceFormat(value);
    }
  },
  components: {
    Pagination,
    DuesListForm,
    ModalConfirm
  },
  computed: {
    ...mapGetters(["g_darkmode", "g_user"])
  },
  data() {
    return {
      total: 0,
      length: 0,
      duesDetail: {},
      statusList: [],
      categoryList: [],
      payerList: [],
      repaidList: [
        { label: i18n.t("app.yes"), key: 1 },
        { label: i18n.t("app.no"), key: 0 }
      ],
      queryData: {
        due: this.$route.params.id,
        status: 1,
        page: 1,
        limit: 10,
        category: null,
        payer: null
      },
      loading: false,
      dataTable: [],
      tableHeaders: [
        { text: i18n.t("dues.table.dues_name"), value: "name", width: 100 },
        { text: i18n.t("dues.table.level"), value: "category" },
        { text: i18n.t("dues.table.level_name"), value: "payer_name" },
        {
          text: "Nominal",
          value: "nominal"
        },
        { text: i18n.t("dues.table.status"), value: "status" },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 100
        }
      ],
      dialogMasterForm: false,
      selectedDuesMaster: null,
      dialogMasterTypeForm: false,
      confirmDelete: false,
      loadingDelete: false,
      loadingPayer: false,
      tempDelete: {}
    };
  },
  created() {
    detailDuesMaster({ id: this.$route.params.id }).then(res => {
      this.duesDetail = res.data.data;
    });
    getDuesStatus().then(res => {
      res.data.code ? (this.statusList = res.data.data) : "";
    });
    getDuesCategory().then(res => {
      res.data.code ? (this.categoryList = res.data.data) : "";
    });
  },
  mounted() {
    this.queryData.school_year_master = this.g_user.active_schoolyear.school_year_master;
    this.getDuesMasterHandler();
  },
  methods: {
    async getDuesPayerHandler() {
      if (this.queryData.category) {
        this.loadingPayer = true;
        await getDuesPayer({
          category: this.queryData.category,
          school_year_master: this.g_user.active_schoolyear.school_year_master
        }).then(res => {
          res.data.code ? (this.payerList = res.data.data) : "";
          this.loadingPayer = false;
        });
      } else this.queryData.payer = null;
      this.getDuesMasterHandler();
    },
    setEdit(dues) {
      this.selectedDuesMaster = dues;
      this.dialogMasterForm = true;
    },
    setAction(param, data) {
      if (param == "master" && data) {
        this.selectedDuesMaster = data;
        this.dialogMasterForm = true;
      } else if (param == "master") {
        this.selectedDuesMaster = {};
        this.dialogMasterForm = true;
      } else {
        this.dialogMasterTypeForm = true;
      }
    },
    getDuesMasterHandler() {
      this.loading = true;
      getDuesList(this.queryData)
        .then(res => {
          const data = res.data.data;
          if (res.data.code) {
            this.dataTable = data.data;
            this.total = data.total;
            this.length = data.last_page;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getDuesMasterHandler();
      }, doneTypingInterval);
    },
    setDeleteDues(item) {
      this.tempDelete = item;
      this.confirmDelete = true;
    },
    deleteMasterDues() {
      this.loadingDelete = true;
      setStatusDues({
        id: this.tempDelete.id
      })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: i18n.t("app.success_delete"),
              color: "success"
            });

            this.confirmDelete = false;
            this.getDuesMasterHandler();
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingDelete = false;
        })
        .catch(() => (this.loadingDelete = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getDuesMasterHandler();
    }
  }
};
</script>
