<template>
  <div>
    <v-dialog
      v-model="isOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("dues.master_dues.dialog.add_dues") }}
          <v-spacer></v-spacer>
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <v-form v-model="valid" ref="formDues" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('dues.table.dues_name')"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="form.category"
                  :label="$t('dues.table.level')"
                  :rules="[v => !!v || $t('app.required')]"
                  :loading="loadingGrade"
                  :items="categoryList"
                  :disabled="isEdit"
                  item-text="label"
                  item-value="key"
                  outlined
                  dense
                  @change="getDuesPayerHandler"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="isEdit && form.category === 'person'"
                  :value="form.payer_name"
                  :label="$t('app.student')"
                  disabled
                  outlined
                  dense
                />
                <v-text-field
                  v-else-if="isEdit"
                  :value="form.payer_name"
                  :label="$t('dues.table.level_name')"
                  disabled
                  outlined
                  dense
                />
                <v-autocomplete
                  v-else
                  v-model="levelNameModel"
                  :label="$t('dues.table.level_name')"
                  :rules="[v => v.length > 0 || $t('app.required')]"
                  :loading="loadingLevelName"
                  :disabled="!form.category"
                  :items="payerList"
                  multiple
                  item-text="label"
                  item-value="key"
                  outlined
                  dense
                  @change="onChangePayer"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">
                      {{ item.label }}
                    </span>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ levelNameModel.length - 1 }} {{ $t("app.others") }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-if="!isEdit && form.category === 'person'"
                  v-model="studentModel"
                  :label="$t('app.student')"
                  :rules="[v => v.length > 0 || $t('app.required')]"
                  :loading="loadingStudent"
                  :items="studentList"
                  multiple
                  item-text="label"
                  item-value="key"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">
                      {{ item.label }}
                    </span>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ studentModel.length - 1 }} {{ $t("app.others") }})
                    </span>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model.number="form.nominal"
                  :rules="[v => !!v || $t('app.required')]"
                  label="Rp. Nominal"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="!isEdit && form.category === 'person'"
                  v-model.number="form.nominal"
                  :rules="[v => !!v || $t('app.required')]"
                  label="Rp. Nominal"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="my-2" no-gutters>
            <v-spacer />
            <v-btn
              :loading="loadingSave"
              class="gradient-primary"
              dark
              depressed
              @click="saveMaster()"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment";
import { createDues, getDuesPayer, editDues } from "@/api/admin/transactions";

export default {
  props: {
    isOpen: Boolean,
    data: Object,
    close: Function,
    categoryList: Array,
    schoolYearId: [String, Number]
  },
  data() {
    return {
      valid: false,
      currentDate: moment().format("YYYY-MM-DD"),
      payerList: [],
      tableHeaders: [
        {
          text: i18n.t("app.school_year"),
          align: "left",
          value: "school_year"
        },
        { text: i18n.t("app.grade"), value: "grade", width: 100 },
        { text: i18n.t("dues.dues_type"), value: "dues_type" },
        {
          text: i18n.t("personalization.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      loading: false,
      loadingSave: false,
      loadingLevelName: false,
      schoolYearList: [],
      gradeList: [],
      duesList: [],
      studentList: [],
      form: {
        school_year_master: this.schoolYearId,
        name: "",
        category: "",
        payer: [],
        nominal: null
      },
      levelNameModel: [],
      studentModel: [],
      loadingSchoolYear: false,
      loadingGrade: false,
      loadingDues: false,
      loadingStudent: false,
      isEdit: false
    };
  },
  watch: {
    async isOpen(newVal) {
      if (newVal) {
        this.isEdit = false;

        if (this.data) {
          let data = JSON.parse(JSON.stringify(this.data));
          data.nominal = data.nominal ? Number(data.nominal) : null;
          this.form = data;
          this.isEdit = data.id ? true : false;
          this.getDuesPayerHandler();
        }

        if (!this.isEdit) {
          this.$refs.formDues ? this.$refs.formDues.reset() : "";
        }
      }
    }
  },
  methods: {
    onChangePayer(value) {
      if (this.form.category === "person") {
        this.loadingStudent = true;
        this.studentModel.length = 0;
        getDuesPayer({
          category: this.form.category,
          school_year_master: this.schoolYearId,
          class: value
        }).then(res => {
          const { code, data } = res.data;
          if (code === 1) this.studentList = data;
          this.loadingStudent = false;
        });
      }
    },
    getDuesPayerHandler() {
      const category = this.form.category;
      if (category) {
        this.loadingLevelName = true;
        this.levelNameModel.length = 0;
        getDuesPayer({
          category: category === "person" ? "class" : category,
          school_year_master: this.schoolYearId
        }).then(res => {
          res.data.code ? (this.payerList = res.data.data) : "";
          this.loadingLevelName = false;
        });
      }
    },
    saveMaster() {
      if (this.$refs.formDues.validate()) {
        this.loadingSave = true;
        this.form.due = this.$route.params.id;
        if (this.isEdit) {
          editDues(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("app.success_edit"),
                  color: "success"
                });
                this.close("save");
                this.$refs.form.reset();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
          this.loadingSave = false;
        } else {
          const { category } = this.form;
          this.form.payer =
            category === "person" ? this.studentModel : this.levelNameModel;
          createDues(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("app.success_save"),
                  color: "success"
                });
                this.studentModel = [];
                this.levelNameModel = [];
                this.close("save");
                this.$refs.form.reset();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
        }
      }
    }
  }
};
</script>
